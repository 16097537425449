import { useEffect, useState } from "react";

export default function TypeformWidget({ isMobile }) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            const typeformElement = document.getElementsByClassName('tf-v1-sidetab')[0];
            if (typeformElement) {
                typeformElement.remove();
            }
            document.body.removeChild(script);
        };
    }, []);

    return <div data-tf-live={isMobile ? "01JGNWK5MDS3X40J4Q64N9BV3B" : "01HG87YFK94VNMH1FPZB1C1ESZ"} />;
};
