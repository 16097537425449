import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Box, Grid, Modal, Typography, useTheme, Alert } from "@mui/material"
import { API_URL, APP_NAME, TITLE_SEPERATOR } from "../../hooks/globals";
import useConsoleLog from "../../hooks/useConsoleLog";
import { useAuth } from "../../auth/Authentication";
import useGetListing from "../../api/useGetListing";
import ListingLayout from "./orders/OrdersListingLayout";
import Intro from "./trees/Intro";
import CollectiveContribution from "./trees/CollectiveContribution";
import "../../scss/trees.scss";
import StyledBox from "../../theme/artist/overrides/StyledBox";
import IndividualContribution from "./trees/IndividualContribution";
import FeedbackForm from "./forms/FeedbackForm";
import TypeformWidget from "./forms/TypeformWidget";

export default function ArtistOrders() {
    const cookies = new Cookies();
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState();
    const auth = useAuth();
    const navigate = useNavigate();
    const getListing = useGetListing();
    const {userId} = useParams();
    const [plantedIndi, setPlantedIndi] = useState(0);
    const log = useConsoleLog;
    const [trees, setTrees] = useState(0);
    const [open, setOpen] = useState(false);
    const url = "https://www.yougrowpromo.com/";
    const shareTitle = `I planted ${plantedIndi} trees`;
    const shareMessage = `I planted ${plantedIndi} trees by ordering with www.yougrowpromo.com`;
    const theme = useTheme();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    window.twttr = (function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        const t = window.twttr || {};
        if (d.getElementById(id)) return t;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://platform.twitter.com/widgets.js";
        fjs.parentNode.insertBefore(js, fjs);
      
        t._e = [];
        t.ready = function(f) {
          t._e.push(f);
        };
      
        return t;
      }(document, "script", "twitter-wjs"));
      

    const getTreeData = () => {
        getListing.getListing("trees").then((response) => {
            setTrees(response.data);
            log(response);
        }).catch((err) => {
            log(err);
        });
    }

    useEffect(() => {
        if (!auth.isAuthed()) {
          navigate("/login")
        }
        getTreeData();
    }, []);

    if (auth.user) {
        return (
            <>
            <Box className="container">
                <Helmet>
                    <title>Orders {TITLE_SEPERATOR} {APP_NAME}</title>
                </Helmet>
                <ListingLayout
                    name="Orders" 
                    TABLE_HEAD={[
                    { id: 'Order_Date__c', label: 'Order date', alignRight: false },
                    { id: 'Id', label: 'Order number', alignRight: false },
                    { id: 'SpotifyTrackTitle__c', label: 'Song name', alignRight: false },
                    { id: 'Package__c', label: 'Name of package', alignRight: false },
                    { id: 'StageName', label: 'Order status', alignRight: false, alignMRight: true },
                    { id: '', label: '', alignRight: false },
                    ]}
                    apiPath="artist/orders"
                    apiAdminPath="admin/artist-orders"
                    pageTitle="Your YouGrow orders"
                    adminPageTitle="YouGrow orders from user email "
                    loading={isLoading}
                    artistId={userId}
                    theChatMessages={messages}
                    onSetTreesIndi={setPlantedIndi}
                />
                <StyledBox>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Intro/>
                        </Grid>
                    </Grid>
                    <Grid container className="trees-planted" spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="contibution-title">
                                Since May 15th 2023
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <IndividualContribution 
                                planted={plantedIndi}
                                onSetOpen={setOpen}
                            />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <CollectiveContribution
                                planted={trees}
                            />
                        </Grid>
                    </Grid>
                </StyledBox>
            </Box>
            <FeedbackForm />
            <TypeformWidget isMobile={isMobile} />}
            </>
        )
    }

    return null;
}